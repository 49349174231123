import { Guid } from '../models/Guid';
import { isServer } from './browser';

function getItem(key: string): string | undefined {
  if (!key) {
    throw new Error('Key not specified');
  }
  try {
    const result = localStorage.getItem(key);
    return result !== null ? result : undefined;
  } catch (e) {
    console.error(
      `Error getting ${key} from local storage. Error: ${(
        e || ''
      )?.toString()}`,
      { key },
      e
    );
  }
  return undefined;
}

function getItemOrLegacy(key: string, legacyKey: string): string | undefined {
  if (!key) {
    throw new Error('Key not specified');
  }
  try {
    let result = localStorage.getItem(key);
    if (result !== null) {
      return result;
    }
    result = localStorage.getItem(legacyKey);
    if (result !== null) {
      localStorage.setItem(key, result);
      localStorage.removeItem(legacyKey);
      return result;
    }
  } catch (e) {
    console.error(
      'Failed to get local storage item or legacy',
      { key, legacyKey },
      e
    );
  }
  return undefined;
}

function removeItem(key: string): void {
  if (!key) {
    throw new Error('Key not specified');
  }
  try {
    localStorage.removeItem(
      typeof key === 'string' ? key : (key as any).toString()
    );
  } catch (e) {
    console.error('Failed to remove local storage item', { key }, e);
  }
}

function setItem(key: string, value: string): void {
  if (!key) {
    throw new Error('Key not specified');
  }
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.error('Failed to set local storage', { key, value }, e);
  }
}

function tryGetStringOrLegacy(
  key: string,
  legacyKey: string
): [boolean, string?] {
  if (isServer()) return [false, undefined];
  const result = getItemOrLegacy(key, legacyKey);
  const success = result === undefined;
  return [success, success ? result : undefined];
}

function tryGetGuid(key: string): [boolean, Guid?] {
  if (isServer()) return [false, undefined];
  return Guid.tryParse(getItem(key));
}

function tryGetGuidOrLegacy(key: string, legacyKey: string): [boolean, Guid?] {
  if (isServer()) return [false, undefined];
  return Guid.tryParse(getItemOrLegacy(key, legacyKey));
}

export const LocalStorageHelper = {
  getItem,
  getItemOrLegacy,
  removeItem,
  setItem,
  tryGetStringOrLegacy,
  tryGetGuid,
  tryGetGuidOrLegacy
};
