import { Guid } from '@komo-tech/core/models/Guid';
import { LocalStorageHelper } from '@komo-tech/core/utils/localStorage';

import { ApiLocalKeys } from '@/common/data/ApiLocalKeys';

export class AdminCache {
  private static _companyId?: Guid;
  private static _workspaceId?: Guid;
  private static _administratorId?: Guid;
  private static _administratorSessionId?: Guid;

  static get companyId(): Guid | undefined {
    return AdminCache._companyId;
  }

  static get workspaceId(): Guid | undefined {
    return AdminCache._workspaceId;
  }

  static setWorkspace(value: Guid) {
    AdminCache._workspaceId = value;
    LocalStorageHelper.setItem(ApiLocalKeys.Workspace, value.toString());
  }

  static get administratorId(): Guid | undefined {
    return AdminCache._administratorId;
  }

  static get sessionId(): Guid | undefined {
    return AdminCache._administratorSessionId;
  }

  static setCompany(companyId: Guid) {
    AdminCache._companyId = companyId;
    LocalStorageHelper.setItem(ApiLocalKeys.Company, companyId.toString());
  }

  static clearCompany() {
    AdminCache._companyId = null;
    AdminCache._workspaceId = null;
    LocalStorageHelper.setItem(ApiLocalKeys.Company, '');
    LocalStorageHelper.setItem(ApiLocalKeys.Workspace, '');
  }

  static setAdministratorSession(sessionId: Guid) {
    AdminCache._administratorSessionId = sessionId;
    LocalStorageHelper.setItem(
      ApiLocalKeys.AdministratorSession,
      sessionId.toString()
    );
  }

  static setAdministrator(administratorId: Guid) {
    AdminCache._administratorId = administratorId;
    LocalStorageHelper.setItem(
      ApiLocalKeys.Administrator,
      administratorId.toString()
    );
  }

  static load() {
    const [administratorSuccess, administratorId] =
      LocalStorageHelper.tryGetGuidOrLegacy(
        ApiLocalKeys.Administrator,
        ApiLocalKeys.LegacyAdministrator
      );
    if (administratorSuccess) {
      AdminCache._administratorId = administratorId;
    }

    const [sessionSuccess, sessionId] = LocalStorageHelper.tryGetGuid(
      ApiLocalKeys.AdministratorSession
    );
    if (sessionSuccess) {
      AdminCache._administratorSessionId = sessionId;
    }

    const [companySuccess, companyId] = LocalStorageHelper.tryGetGuidOrLegacy(
      ApiLocalKeys.Company,
      ApiLocalKeys.LegacyCompany
    );
    if (companySuccess) {
      AdminCache._companyId = companyId;
    }

    const [workspaceSuccess, workspaceId] = LocalStorageHelper.tryGetGuid(
      ApiLocalKeys.Workspace
    );
    if (workspaceSuccess) {
      AdminCache._workspaceId = workspaceId;
    }
  }

  static hasCredentials() {
    return AdminCache._administratorId;
  }

  static resetAdminSession() {
    AdminCache._administratorId = undefined;
    AdminCache._administratorSessionId = undefined;
    LocalStorageHelper.removeItem(ApiLocalKeys.AdministratorSession);
    LocalStorageHelper.removeItem(ApiLocalKeys.Administrator);
  }
}
