import { isServer } from '@komo-tech/core/utils/browser';
import { ApiBase } from '@komo-tech/ui/api/Base';
import { ApiHeaders } from '@komo-tech/ui/api/constants';
import { ApiError } from '@komo-tech/ui/api/types';

import { KomoApiBaseUrl } from '@/common/utils/Constants';

import { AdminCache } from './AdminCache';

const isAuthorizationError = (response: Response) => response.status === 401;

const adminResponseHandler = (
  response: Response,
  innerHandler?: (res) => any
) => {
  return innerHandler ? innerHandler(response) : undefined;
};

const adminErrorHandler = async (
  response: Response,
  innerHandler?: (res) => any
) => {
  if (!isAuthorizationError(response)) {
    if (innerHandler) return innerHandler(response);
    throw await ApiError.create(response);
  }

  if (window.location.href.includes('portal/auth/login')) return null;
  window.location.href = `/portal/auth/login?redirectUrl=${encodeURIComponent(window.location.toString())}`;
  throw await ApiError.create(response);
};

const getAdminHeaders = (): HeadersInit => {
  const headers = {} as HeadersInit;

  const companyId = AdminCache.companyId;
  if (companyId) {
    headers[ApiHeaders.CompanyId] = companyId.toString();
  }

  if (isServer()) {
    headers[ApiHeaders.Ssr] = true;
  }

  return headers;
};

/**
 * This class should not be used directly unless for testing.
 *
 * Use "AdminLiveApi" or "AdminHubsApi".
 */
export class AdminApi extends ApiBase {
  constructor(basePathFn: () => string) {
    super(basePathFn, (config) => {
      config = config || {};
      return {
        ...config,
        headers: {
          ...getAdminHeaders(),
          ...config.headers
        },
        responseHandler: (r) => adminResponseHandler(r, config.responseHandler),
        errorHandlerAsync: (r) => adminErrorHandler(r, config.errorHandlerAsync)
      };
    });
  }
}

export const AdminLiveApi = new AdminApi(
  () => `${KomoApiBaseUrl}/api/v1/live-admin`
);
export const AdminHubsApi = new AdminApi(
  () => `${KomoApiBaseUrl}/api/v1/hubs-admin`
);
